/* CurrencyConverter.css */

.container {
  max-width: 600px;
}

.card {
  border-radius: 25px; /* Increase the border-radius for rounder edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #d7f3ff; /* Light blue background color */
  padding: 20px; /* Add padding for better spacing inside the card */
}

label {
  font-size: 1rem;
}

.form-control {
  font-size: 1rem;
}

.btn {
  width: 100%;
}

.datepicker-input {
  padding: .375rem .75rem;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.converter-container {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
