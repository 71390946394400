/* App.css */

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header, .App-container {
  background-color: #d7f3ff; /* Light blue background color */
  padding: 10px;
  border-bottom: 1px solid #ccc;
  border-radius: 15px; /* Adjust the value as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.nav-container, .footer-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  width: 50px;
  height: auto;
}

.nav-links {
  display: flex;
  gap: 1px;
  align-items: center;
}

.nav-links a, .sign-out-button {
  color: #00f; /* Light blue color */
  text-decoration: none;
  font-size: 1rem;
  padding: 5px 10px; /* Add padding for better alignment */
  display: flex;
  align-items: center;
}

.nav-links a:hover, .sign-out-button:hover {
  text-decoration: underline;
}

.sign-out-button {
  background: none;
  border: none;
  cursor: pointer;
}

.App-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 20px; /* Increased top padding to give space below the header */
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}

.content-container {
  padding-top: 30px; /* Additional padding to ensure the content clears the header */
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}

.blotter-title {
  margin-bottom: 20px;
}

.small-link {
  font-size: 0.8rem;
  color: #00f; /* Light blue color */
  text-decoration: none;
}

.small-link:hover {
  text-decoration: underline;
}

/* Additional styles for the auth container */

.auth-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the top */
  min-height: 100vh;
  padding-top: 20px; /* Add some padding at the top */
  max-width: 600px; /* Ensure the auth container is not too wide */
  margin: 0 auto; /* Center the auth container */
}

.amplify-authenticator {
  width: 100%;
  max-width: 600px; /* Match the max-width of .content-container */
  padding: 20px; /* Match the padding of .content-container */
  background: white; /* Match the background of .content-container */
  border-radius: 25px; /* Match the border-radius of .content-container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Match the box-shadow of .content-container */
}

.amplify-flex {
  margin: 0 auto; /* Center align */
  padding: 10px;
}

.amplify-button {
  border-radius: 8px; /* Rounded buttons */
  padding: 0.8rem 1.2rem; /* Adjust button padding */
}

.amplify-tabs__item {
  margin: 0.5rem;
}

.amplify-field__show-password {
  display: none; /* Hide the "Password is hidden" message */
}

.amplify-field-group__control {
  margin-bottom: 1rem; /* Space between input fields */
}

/* Ensuring the tabs and other elements are also wider */
[data-amplify-container] {
  max-width: 600px;
  width: 400px;
  margin: 0 auto; /* Center these elements */
  padding: 20px; /* Ensure padding for consistency */
  background: white; /* Ensure background matches */
  border-radius: 25px; /* Ensure border-radius matches */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ensure box-shadow matches */
}

[data-amplify-footer] {
  margin-left: auto; /* Right align */
  width: 50%;
}

.error-message {
  color: red;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: #d7f3ff; /* Light blue background color */
  color: green;
  cursor: pointer;
}
